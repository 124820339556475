<template>
  <div class="home">
    <headertop />
    <div class="backimg"></div>
    <div class="introduce">
      <div class="classification">
        <ul>
          <li>
            <dt><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/钱(2).png" alt="" /></dt>
            <p><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/钱3.png" alt="" /></p>
            <dd>价格优势</dd>
          </li>
          <!-- <span class="fenge"></span> -->
          <li>
            <dt><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/时效组件(1).png" alt="" /></dt>
            <p><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/时效组件.png" alt="" /></p>
            <dd>时效迅速</dd>
          </li>
          <!-- <span class="fenge"></span> -->
          <li>
            <dt><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/真实可信.png" alt="" /></dt>
            <p><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/真实可信1.png" alt="" /></p>
            <dd>真实可信</dd>
          </li>
          <!-- <span class="fenge"></span> -->
          <li>
            <dt><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/安全 (1).png" alt="" /></dt>
            <p><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/安全 (2).png" alt="" /></p>
            <dd>安全放心</dd>
          </li>
          <!-- <span class="fenge"></span> -->
          <li style="border: none">
            <dt><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/闪电 (2).png" alt="" /></dt>
            <p><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/闪电 (3).png" alt="" /></p>
            <dd>操作快捷</dd>
          </li>
        </ul>
      </div>
      <div class="jieshao">
        <b>平台介绍</b>
        <p>PLAFORM INTRODUCTION</p>
        <span
          style="
            width: 100px;
            display: block;
            margin: 0 auto;
            margin-top: 10px;
            height: 4px;
            background: #4272c3;
          "
        ></span>
      </div>
      <div>
        <div class="xiangxijieshao">
          <div class="jieshaotext">
            <span
              style="display: inline-block; margin-top: 73px; margin-left: 79px"
              >泽诚货运平台 ：
              <p style="display: inline-block; color: #4272c3">
                隶属于陕西泽诚供应链管理有限公司建设并运营。
              </p>
            </span>
            <p
              style="
                color: #888888;
                margin-top: 22px;
                margin-left: 79px;
                margin-right: 74px;
              "
            >
              平台围绕物流、信息流、资金流、发票流、合同流五流打造自动化、可视化、可控化、智能化于一体的物流云管理平台。通过集约整合和科学调度车辆、货源等零散物流资源，有效提升运输组织效率、优化物流市场格局，规范市场主体经营行为，推动货运物流行业升级转型，引导货运行业向集约化、规范化、组织化方向发展；形成“物流+大数据+互联网”相融合的一体化物流产业生态。
            </p>
          </div>
          <img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/img.png" alt="" />
        </div>
      </div>
    </div>
    <div class="advantage">
      <ul>
        <li style="background: none; text-align: center">
          <div class="hexin">
            <h6>核心竞争力</h6>
            <p class="eng" style="color: white">CORECOMPETENCE</p>
          </div>
          <div class="hexintext">
            陕西泽诚供应链管理有限公司以互联网技术与供应链需求为基础，构建围绕物流、信息流、资金流、发票流、合同流五流打造自动化、
            可视化、可控化、智能化于一体的泽诚货运物流云管理平台。
          </div>
        </li>
        <li>
          <b><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/菜单数据统计.png" alt="" /></b>
          <p><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/菜单数据统计(1).png" alt="" /></p>
          <div class="fenleitext">
            <p>
              <b>八大流程的落地能力</b><br />
              按照八大流程运营业务沉淀数据，从而优化流程提高效率。
            </p>
          </div>
        </li>
        <li>
          <b><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/计划调度(1).png" alt="" /></b>
          <p><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/计划调度.png" alt="" /></p>
          <div class="fenleitext">
            <p>
              <b>四层调度的管理能力</b><br />
              通过自身运营能力与第三方线上化平台对接，实现流程自动化。
            </p>
          </div>
        </li>
      </ul>
      <ul style="margin-top: 143px">
        <li>
          <b><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/货车_line(1).png" alt="" /></b>
          <p><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/货车_line.png" alt="" /></p>
          <div class="fenleitext">
            <p>
              <b>运力资源池的运营能力</b><br />
              通过标准化的作业流程帮助企业构建自己的运力资源池。
            </p>
          </div>
        </li>
        <li>
          <b><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/37-服务-线性(1).png" alt="" /></b>
          <p><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/37-服务-线性.png" alt="" /></p>
          <div class="fenleitext">
            <p>
              <b>标准化服务的的系统能力</b><br />
              业务和服务相结合，通过标准化流程提高运行效率。
            </p>
          </div>
        </li>
        <li>
          <b><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/钱(1).png" alt="" /></b>
          <p><img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/钱.png" alt="" /></p>
          <div class="fenleitext">
            <p>
              <b>系统结算的财务能力</b><br />
              实时在线把控财务风险，实现在线结算，自动生成核算报表。
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div class="value">
      <div class="jieshao1">
        <b>平台价值</b>
        <p>PLATFORM VALUE</p>
        <span
          style="
            width: 100px;
            display: block;
            margin: 0 auto;
            margin-top: 10px;
            height: 4px;
            background: #4272c3;
          "
        ></span>
      </div>
      <span class="left" @click="arrowClick('left')"></span>
      <el-carousel
        :interval="3000"
        indicator-position="none"
        arrow="never"
        :autoplay="false"
        ref="cardShow"
      >
        <el-carousel-item>
          <li id="move" ref="move" @mouseenter="enter" @mouseleave="leave">
            <b id="move1" ref="move1">降低货物风险</b>
            <p id="move2" ref="move2">
              利用大数据技术车辆在途运输全程可视、可控、可追溯，异常情况智能告警，及时了解运单在途状态，解决车辆在途问题，缩短商品流通周期
              ，提高对货物运输全程的管控力，从而实现灵活调度、应急响应、态势感知和决策分析。
            </p>
          </li>
          <li>
            <b>运输环节透明化</b>
            <p>
              平台上下对接货主、实际承运人及司机等实现信息对称及运输效率的大幅提升，对于运力管理、结算、对账、保险等都可以实现一键操作，车辆轨迹实时更新，减少原有的层层转包环节，降低运输成本，让运输环节更加透明。
            </p>
          </li>
          <li>
            <b>生态多边链接</b>
            <p>
              平台直接对接国家税务监管部门，能兼容各类外部及内部系统接口。平台可对接保险、金融、ETC等等，形成生态联盟，还可对接其他网络货运平台，共享车辆资源，打破信息孤岛，形成一条可控的供应链体系，实现高效协同、合作共赢。
            </p>
          </li>
          <li>
            <b>税务合规筹划</b>
            <p>
              结合云资金管理平台（网商银行在线支付系统），支持货主企业，运营平台、承运司机费用一体化管理，财务信息节点清晰明了，系统收入、支出一目了然；同时上报国家相关检测系统，实现税务合规的真实性。
            </p>
          </li>
        </el-carousel-item>
        <el-carousel-item>
          <li>
            <b>高效协同管理</b>
            <p>
              系统对接货主、承运商两方，同时数据同步省检测平台、运管局、国家税务系统，实现了多方信息的互通，降低行业成本，加速行业的转型升级和效率提升。
            </p>
          </li>
          <li>
            <b>税务合规</b>
            <p>
              结合运资金管理平台（银行在线支付系统），支持货主企业。运营平台、承运司机费用一体化管理，财务信息节点清晰明了，系统收入、支出一目了然；同时上报国家相关检测系统，实现税务合规的真实性。
            </p>
          </li>
          <li>
            <b>智能数据报表</b>
            <p>
              基于车辆在途信息，系统自动生成物流业务数据分析报表，从而帮助企业精准的运营分析增强自身运营管理能力并实现了对承运商的KPI考核。
            </p>
          </li>
        </el-carousel-item>
         <el-carousel-item>
          <li>
            <b>降低货物风险</b>
            <p>
              利用大数据技术车辆在途运输全程可视、可控、可追溯，异常情况智能告警，及时了解运单在途状态，解决车辆在途问题，缩短商品流通周期
              ，提高对货物运输全程的管控力，从而实现灵活调度、应急响应、态势感知和决策分析。
            </p>
          </li>
          <li>
            <b>运输环节透明化</b>
            <p>
              平台上下对接货主、实际承运人及司机等实现信息对称及运输效率的大幅提升，对于运力管理、结算、对账、保险等都可以实现一键操作，车辆轨迹实时更新，减少原有的层层转包环节，降低运输成本，让运输环节更加透明。
            </p>
          </li>
          <li>
            <b>生态多边链接</b>
            <p>
              平台直接对接国家税务监管部门，能兼容各类外部及内部系统接口。平台可对接保险、金融、ETC等等，形成生态联盟，还可对接其他网络货运平台，共享车辆资源，打破信息孤岛，形成一条可控的供应链体系，实现高效协同、合作共赢。
            </p>
          </li>
          <li>
            <b>税务合规筹划</b>
            <p>
              结合云资金管理平台（网商银行在线支付系统），支持货主企业，运营平台、承运司机费用一体化管理，财务信息节点清晰明了，系统收入、支出一目了然；同时上报国家相关检测系统，实现税务合规的真实性。
            </p>
          </li>
        </el-carousel-item>
        <el-carousel-item>
          <li>
            <b>高效协同管理</b>
            <p>
              系统对接货主、承运商两方，同时数据同步省检测平台、运管局、国家税务系统，实现了多方信息的互通，降低行业成本，加速行业的转型升级和效率提升。
            </p>
          </li>
          <li>
            <b>税务合规</b>
            <p>
              结合运资金管理平台（银行在线支付系统），支持货主企业。运营平台、承运司机费用一体化管理，财务信息节点清晰明了，系统收入、支出一目了然；同时上报国家相关检测系统，实现税务合规的真实性。
            </p>
          </li>
          <li>
            <b>智能数据报表</b>
            <p>
              基于车辆在途信息，系统自动生成物流业务数据分析报表，从而帮助企业精准的运营分析增强自身运营管理能力并实现了对承运商的KPI考核。
            </p>
          </li>
        </el-carousel-item>
      </el-carousel>
      <span class="right" @click="arrowClick('right')"></span>
    </div>
    <div class="xicheng">
      <div class="wulian">
        <b>泽诚货运</b>
        <p>XI&nbsp;&nbsp;CHENG&nbsp;&nbsp; WU&nbsp;&nbsp; LIAN</p>
        <span
          style="
            width: 100px;
            display: block;
            margin: 0 auto;
            margin-top: 10px;
            height: 4px;
            background: #4272c3;
          "
        ></span>
      </div>
      <div class="shanxi">
        <p>
          陕西泽诚供应链管理有限公司公司以互联网技术与供应链需求为基础，构建围绕物流、信息流、资金流、发票流、
          合同流五流打造自动化、
          可视化、可控化、智能化于一体的熙诚物联物流云管理平台。
        </p>
      </div>

      <div class="amount">
        <ul>
          <li>
            <countTo
              class="count"
              :startVal="startdan"
              :endVal="enddan"
              :duration="3000"
            ></countTo
            ><span class="danwei">单</span>
            <p>合计运单</p>
          </li>
          <span class="kuai"></span>
          <li>
            <countTo
              class="count"
              :startVal="startdun"
              :endVal="enddun"
              :duration="3000"
            ></countTo
            ><span class="danwei">吨</span>
            <p>合计运量</p>
          </li>
          <span class="kuai"></span>
          <li>
            <countTo
              class="count"
              :startVal="startliang"
              :endVal="endliang"
              :duration="3000"
            ></countTo
            ><span class="danwei">辆</span>
            <p>平台车辆</p>
          </li>
          <span class="kuai"></span>
          <li>
            <countTo
              class="count"
              :startVal="startren"
              :endVal="endren"
              :duration="3000"
            ></countTo
            ><span class="danwei">人</span>
            <p>平台司机</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="gonggao">
      <div class="jieshao1">
        <b>信息公告</b>
        <p>INFORMATION&nbsp;&nbsp;BULLETIN</p>
        <span
          style="
            width: 100px;
            display: block;
            margin: 0 auto;
            margin-top: 10px;
            height: 4px;
            background: #4272c3;
          "
        ></span>
      </div>
      <ul>
        <li style="height: 407px; width: 580px">
          <img src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/img2.png" alt="" />
          <img
            src="https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/more.png"
            alt=""
            style="margin-left: 466px; margin-top: 5px"
            @click="goinfo"
          />
        </li>
        <li class="date">
          <div class="date1" v-for="(item, index) of infolist" :key="index">
            <b>{{ item.releasedate | dateformat("MM-DD") }}</b>
            <p>{{ item.releasedate | dateformat("YYYY") }}</p>
          </div>
        </li>
        <li class="date2">
          <div
            class="date3"
            v-for="(item, index) of infolist"
            :key="index"
            @click="godetail(item.newsid)"
          >
            <b
              style="
                width: 300px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: pointer;
                display: block;
              "
              >{{ item.title }}</b
            >
            <p
              style="
                width: 500px;
                overflow: hidden;
                white-space: nowrap;
                cursor: pointer;
                text-overflow: ellipsis;
              "
            >
              {{ item.summary }}
            </p>
          </div>
        </li>
      </ul>
    </div>
    <bottombar />
  </div>
</template>

<script>
// @ is an alias to /src
import headertop from "@/components/headertop.vue";
import bottombar from "@/components/bottombar.vue";
import { getinfoList } from "@/api/test";
import countTo from "vue-count-to";
export default {
  name: "Home",
  components: {
    headertop,
    bottombar,
    countTo,
  },
  data() {
    return {
      infolist: [],
      //数字开始
      startdan: 0,
      startdun: 0,
      startliang: 0,
      startren: 0,
      //数字结束
      enddan: 14807,
      enddun: 468641.55,
      endliang: 1363,
      endren: 1326,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    arrowClick(val) {
      if (val === "right") {
        this.$refs.move.style.background = "#EEEEEE";
        this.$refs.move1.style.color = "black";
        this.$refs.move2.style.color = "#666666";
        this.$refs.cardShow.next();
      } else {
        this.$refs.move.style.backgeround = "white";
        this.$refs.move1.style.color = "black";
        this.$refs.move2.style.color = "#666666";
        this.$refs.cardShow.prev();
      }
    },
    enter: function () {
      this.$refs.move.style.background = "url(bj3.png)";
      this.$refs.move1.style.color = "white";
      this.$refs.move2.style.color = "white";
    },
    leave: function () {
      this.$refs.move.style.background = "#EEEEEE";
      this.$refs.move1.style.color = "black";
      this.$refs.move2.style.color = "#666666";
    },
    initData() {
      getinfoList().then((res) => {
        console.log(res.data);
        this.infolist = res.data.data;
      });
    },
    godetail(newsid) {
      console.log(newsid);
      this.$router.push({
        path: "/details",
        query: {
          newsid,
        },
      });
    },
    goinfo() {
      this.$router.push("/Information");
    },
  },
};
</script>
<style scoped>
.backimg {
  height: 690px;
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/banner.png) no-repeat;
}

.introduce {
  height: 900px;
  position: relative;
  background-color: #ffffff;
}

.classification {
  width: 1200px;
  height: 197px;
  position: absolute;
  box-shadow: 0px 1px 29px 0px rgba(124, 124, 124, 0.35);
  border-radius: 10px;
  background-color: white;
  left: 0;
  right: 0;
  top: -98px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
}
.classification ul {
  width: 1200px;
  height: 197px;
  display: flex;
  /* background-color: blanchedalmond; */
  justify-content: space-between;
  align-items: center;
}

.classification ul li {
  height: 156px;
  width: 240px;
  border-right: 1px solid #eeeeee;
  text-align: center;
  /* background-color: violet; */
}
.classification ul li:hover {
  height: 240px;
  width: 240px;
  border-radius: 10px;
  border: none;
  padding-top: 20px;
  background-color: #4272c3;
}
.classification ul li:hover dt {
  display: none;
}
.classification ul li p {
  display: none;
}
.classification ul li:hover p {
  display: block;
  margin-top: 40px;
}
.classification ul li:hover dd {
  color: white;
  margin-top: 20px;
}
.classification ul li dd {
  margin-top: 20px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #000000;
}
.classification ul li dt img {
  margin-top: 20px;
}
/* .fenge {
  display: inline-block;
  height: 121px;
  width: 2px;
  background-color: #eeeeee;
} */

.jieshao,
.jieshao1,
.wulian {
  width: 1200px;
  height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  top: 205px;
  margin: 0 auto;
  text-align: center;
}

.jieshao b,
.jieshao1 b {
  font-size: 39px;
}
.wulian b {
  font-size: 39px;
  color: white;
}
.jieshao p,
.jieshao1 p,
.wulian p {
  color: #cccccc;
  font-size: 20px;
  margin-top: 10px;
}

.xiangxijieshao {
  width: 1200px;
  height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  top: 380px;
  margin: 0 auto;
}

.jieshaotext {
  position: absolute;
  background-color: #f5f5f5;
  font-size: 16px;
  top: 66px;
  width: 728px;
  height: 330px;
}
.jieshaotext p {
  line-height: 32px;
}
.xiangxijieshao img {
  margin-left: 608px;
}
.advantage {
  height: 980px;
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/bj.png) no-repeat;
  overflow: hidden;
  background-size: 100% 100%;
}
.advantage ul {
  width: 1200px;
  height: 207px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 214px;
}
.advantage ul li {
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/矩形24.png) no-repeat;
  position: relative;
  width: 30%;
  height: 207px;
}
.advantage ul li:hover {
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/矩形\ 24.png) no-repeat;
  color: white;
}
.advantage ul li:hover b {
  color: white;
}
.advantage ul li:hover p {
  color: white;
}
.hexin h6 {
  font-size: 39px;
  color: #ffffff;
}

.hexin p {
  color: #cccccc;
  font-size: 20px;
}
.hexintext {
  margin: 0 auto;
  margin-top: 10px;
  text-align: left;
  color: #ffffff;
  font-size: 16px;
  height: 128px;
  width: 291px;
  line-height: 26px;
}
.advantage ul li p img {
  position: absolute;
  top: -30px;
  left: 65px;
}
.advantage ul li b img {
  position: absolute;
  top: -30px;
  left: 65px;
  display: none;
}
.advantage ul li:hover b .eng {
  color: white;
}
.advantage ul li:hover b img {
  display: block;
}
.advantage ul li:hover p img {
  display: none;
}
.fenleitext {
  width: 300px;
}
.fenleitext p b {
  display: block;
  font-size: 20px;
  color: #4d84e0;
  margin-top: 70px;
}
.fenleitext p {
  color: #4d84e0;
  margin-left: 40px;
}
.gonggao {
  height: 845px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.value {
  background-color: #ffffff;
  height: 845px;
  position: relative;
  overflow: hidden;
}
.gonggao {
  height: 700px;
}
.jieshao1 {
  position: absolute;
  top: 80px;
}
.el-carousel {
  margin-top: 290px;
  margin-left: 358px;
  width: 1200px;
  height: 485px;
}
.el-carousel__item {
  width: 1200px;
  height: 485px;
}
/* .value ul {
  width: 1200px;
  height: 485px;
  margin-top: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.el-carousel li {
  width: 285px;
  height: 485px;
  float: left;
  background: #eeeeee;
  border: 1px solid #eeeeee;
  padding-top: 64px;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;
}
.left {
  display: block;
  position: absolute;
  top: 500px;
  left: 250px;
  width: 15px;
  height: 27px;
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/下拉拷贝3.png);
}
.right {
  display: block;
  position: absolute;
  top: 500px;
  right: 250px;
  width: 15px;
  height: 27px;
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/下拉4.png);
}
.el-carousel li:hover {
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/bj3.png);
}
#move {
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/bj3.png);
}
/* #move:hover{
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/bj3.png);
} */
#move1 {
  color: white;
}
/* #move1:hover{
  color: #ffffff;
} */
#move2 {
  color: #ffffff;
}
/* #move2:hover{
  color: #ffffff;
} */
.el-carousel li b {
  font-size: 21px;
}
.el-carousel li:hover b {
  color: #ffffff;
}
.el-carousel li:hover p {
  color: #ffffff;
}
.el-carousel li p {
  color: #666666;
  font-size: 16px;
  text-align: left;
  margin-top: 16px;
  padding: 15px;
  line-height: 32px;
  margin-left: 10px;
}
.xicheng {
  height: 680px;
  position: relative;
  background: url(https://xichengchengwulia.oss-cn-beijing.aliyuncs.com/www/assets/img/bj1.png) no-repeat;
  background-size: 100% 100%;
}
.wulian {
  position: absolute;
  top: 80px;
}
.shanxi {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shanxi p {
  width: 740px;
  height: 52px;
  position: absolute;
  top: 222px;
  color: #ffffff;
  text-align: center;
  line-height: 36px;
}

.amount {
  width: 1200px;
  height: 200px;
  position: absolute;
  border-radius: 10px;
  left: 0;
  right: 0;
  top: 360px;
  margin: 0 auto;
}

.amount ul {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.amount ul li {
  height: 156px;
  width: 240px;
  text-align: center;
  padding-top: 40px;
}

.count {
  margin-top: 20px;
  font-size: 46px;
  color: white;
  font-weight: bold;
}
.danwei {
  font-size: 16px;
  color: white;
}
.amount ul li p {
  display: block;
  color: white;
}
.kuai {
  display: inline-block;
  height: 19px;
  width: 19px;
  margin-top: -30px;
  background: #ededed;
  border: 2px solid #ffffff;
  opacity: 0.57;
}
.gonggao ul {
  height: 407px;
  width: 1200px;
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .gonggao ul li{
        height:407px ;
        width: 580px;
    } */
.date {
  width: 72px;
  height: 407px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.date .date1 {
  width: 72px;
}
.date1:hover b {
  color: #3f7fed;
}
.date1:hover p {
  color: #3f7fed;
}
.date3:hover b {
  color: #3f7fed;
}
.date .date1 b {
  font-size: 21px;
}
.date .date1 p {
  font-size: 11px;
  display: inline-block;
}
.date2 {
  width: 500px;
  height: 407px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.date2 .date3 {
  width: 447px;
}
.date2 .date3 b {
  font-size: 16px;
}
.date2 .date3 p {
  font-size: 11px;
  display: inline-block;
}
</style>
